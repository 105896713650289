//Announcement Section
export const CREATE_ADMIN_ANNOUNCEMENT = "CREATE_ADMIN_ANNOUNCEMENT";

export const DELETE_ADMIN_ANNOUNCEMENTS = "DELETE_ADMIN_ANNOUNCEMENTS";

export const GET_ADMIN_ANNOUNCEMENTS_BYID = "GET_ADMIN_ANNOUNCEMENTS_BYID";
export const GET_ADMIN_ANNOUNCEMENT = "GET_ADMIN_ANNOUNCEMENT";

export const CLOSE_ANNOUNCEMENTS_BYID = "CLOSE_ANNOUNCEMENTS_BYID";
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";

export const GET_ADMIN_FILTER_ANNOUNCEMENT = "GET_ADMIN_FILTER_ANNOUNCEMENT";
export const GET_ADMIN_TEACHER_FILTER_ANNOUNCEMENT =
  "GET_ADMIN_TEACHER_FILTER_ANNOUNCEMENT";
