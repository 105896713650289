import React, { useState } from "react";
import "./Login.css";
import Logo from "../assets/logo/Image_Logo.png";
import CampusLogo from "../assets/logo/campusLogo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { registeradmin } from "../redux/actions/authactions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { createMessage } from "../redux/actions/alertactions";

function Register() {

  return (
    <div className="main">
      <div id="logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div id="forms">

        <div className="form_input">
          <div className="information">
            <p id="welcome-info">Register</p>
            <p style={{fontSize: 20, textAlign: 'center', fontFamily: '"SmoochSans", sans-serif'}}>You want to be a Classy's member?</p>

            <p style={{fontSize: 20, textAlign: 'center', fontFamily: '"SmoochSans", sans-serif'}}>Let we know you are a:</p>
          </div>
          <div className="btns-container">
            <a href="/teacherRegister" className="login" style={{fontFamily: '"SmoochSans", sans-serif', marginRight: 20}}>
              Teacher
            </a>
            <a href="/studentRegister" className="login" style={{fontFamily: '"SmoochSans", sans-serif'}} >
              Student
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}
export default Register;
