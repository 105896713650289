//GET Information
export const GET_TEACHER_DETAIL = "GET_TEACHER_DETAIL";
export const GET_TEACHER_CLASS = "GET_TEACHER_CLASS";
export const GET_TEACHER_BYID = "GET_TEACHER_BYID";
export const GET_LECTURE_NOTES = "GET_LECTURE_NOTES";
export const GET_LECTURE_NOTES_FILTER = "GET_LECTURE_NOTES_FILTER";
export const GET_TEACHER_GIVEN_ASSIGNMENT = "GET_TEACHER_GIVEN_ASSIGNMENT";
export const GET_TEACHER_ASSIGNMENT_BYID = "GET_TEACHER_ASSIGNMENT_BYID";
export const GET_SUBMITTED_ASSIGNMENT = "GET_SUBMITTED_ASSIGNMENT";
export const GET_TEACHER_ANNOUNCEMENT = "GET_TEACHER_ANNOUNCEMENT";
export const GET_ATTENDANCE_BYID = "GET_ATTENDANCE_BYID";
export const GET_TEACHER_ANNOUNCEMENT_FILTER =
  "GET_TEACHER_ANNOUNCEMENT_FILTER";
export const GET_TEACHER_ANNOUNCEMENTS_BYID = "GET_TEACHER_ANNOUNCEMENTS_BYID";
export const GET_TEACHER_ASSIGN = "GET_TEACHER_ASSIGN";
export const GET_TEACHER_ASSIGNMENT_FILTER = "GET_TEACHER_ASSIGNMENT_FILTER";

//Post Information
export const ADD_TEACHER_DETAIL = "ADD_TEACHER_DETAIL";
export const POST_TEACHER_ANNOUNCEMENT = "POST_TEACHER_ANNOUNCEMENT";
export const ADD_TEACHER_ASSIGNMENT = "ADD_TEACHER_ASSIGNMENT";
export const ADD_LECTURE_NOTES = "ADD_LECTURE_NOTES";
export const ADD_BULK_ATTENDANCE = "ADD_BULK_ATTENDANCE";

//Update Information
export const UPDATE_TEACHER_DETAIL = "UPDATE_TEACHER_DETAIL ";
export const UPDATE_TEACHER_ANNOUNCEMENT = "UPDATE_TEACHER_ANNOUNCEMENT";
export const UPDATE_TEACHER_PHOTO = "UPDATE_TEACHER_PHOTO";
export const CHANGE_TEACHER_ASSIGNMENT = "CHANGE_TEACHER_ASSIGNMENT";
export const CHANGE_STUDENT_ATTENDANCE = "CHANGE_STUDENT_ATTENDANCE";

//Delete Information
export const DELETE_TEACHER_DETAIL = "DELETE_TEACHER_DETAIL";
export const DELETE_LECTURE_NOTES = "DELETE_LECTURE_NOTES";
export const DELETE_TEACHER_GIVEN_ASSIGNMENT =
  "DELETE_TEACHER_GIVEN_ASSIGNMENT";
export const DELETE_TEACHER_ANNOUNCEMENT = "DELETE_TEACHER_ANNOUNCEMENT";

//Assign Teacher
export const ASSIGN_TEACHER_SUBJECTS = "ASSIGN_TEACHER_SUBJECTS";
