export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD";
export const UPDATE_ADMIN_INFO = "UPDATE_ADMIN_INFO";
export const UPDATE_USER_IMAGE = "UPDATE_USER_IMAGE";
export const SAVE_QUERY_DATA = "SAVE_QUERY_DATA";
export const GET_TABLE_DATA = "GET_TABLE_DATA";

//Timetables
export const ADD_TIMETABLES = "ADD_TIMETABLES";
export const DELETE_TIMETABLES = "DELETE_TIMETABLES";
export const UPDATE_TIMETABLES = "UPDATE_TIMETABLES";
export const GET_TIMETABLES = "GET_TIMETABLES";
export const GET_TIMETABLES_ID = "GET_TIMETABLES_ID";
