//Get DataType
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL";
export const GET_STUDENTS_FOR_ATTENDANCE = "GET_STUDENTS_FOR_ATTENDANCE";
export const STUDENT_DATA_LOADING = "STUDENT_DATA_LOADING";
export const GET_STUDENTCLASS_SID = "GET_STUDENTCLASS_SID";
export const GET_STUDENT_USERID = "GET_STUDENT_USERID";

export const GET_STUDENT_CLASS = "GET_STUDENT_CLASS";
export const GET_STUDENT_PARENTS = "GET_STUDENT_PARENTS";
export const GET_STUDENT_PARENTS_BYID = "GET_STUDENT_PARENTS_BYID";

export const GET_STUDENT_ATTENDANCE = "GET_STUDENT_ATTENDANCE";
export const GET_STUDENT_ASSIGNMENT = "GET_STUDENT_ASSIGNMENT";
export const GET_STUDENT_ASSIGNMENT_FILTER = "GET_STUDENT_ASSIGNMENT_FILTER";
export const GET_STUDENT_ATTENDANCE_FILTER = "GET_STUDENT_ATTENDANCE_FILTER";

//Post Datatype
export const ADD_STUDENT_DETAIL = "ADD_STUDENT_DETAIL";
export const ADD_STUDENT_PARENT_DETAIL = "ADD_STUDENT_PARENT_DETAIL";
export const SUBMIT_ASSIGNMENTS = "SUBMIT_ASSIGNMENTS";
export const SUBMIT_ASSIGNMENTS_FILTER = "SUBMIT_ASSIGNMENTS_FILTER";
export const CHANGE_SUBMIT_ASSIGNMENTS = "CHANGE_SUBMIT_ASSIGNMENTS";

//Delete Datatype
export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_ATTENDANCE = "DELETE_ATTENDANCE";

//Change DataType
export const UPDATE_STUDENT_DETAIL = "UPDATE_STUDENT_DETAIL ";
export const UPDATE_STUDENT_PARENT_DETAIL = "UPDATE_STUDENT_PARENT_DETAIL ";
export const UPDATE_STUDENT_ACADEMIC_DETAIL = "UPDATE_STUDENT_ACADEMIC_DETAIL ";
export const UPDATE_STUDENT_PHOTO = "UPDATE_STUDENT_PHOTO ";
