import React, { useState, useEffect } from "react";
import { Layout, InputNumber, Button, Card, Row, Col } from 'antd';
import { Link, Element } from 'react-scroll';
import Logo from "../../../assets/logo/Image_Logo.png";
import QRCode from 'qrcode.react';
import { InstagramFilled } from '@ant-design/icons';
import { FacebookFilled } from '@ant-design/icons';
import qr from '../../../assets/images/qr.jpg';
import Alert from "antd/es/alert/Alert";
import axios from "axios";
import axiosInstance from "../../../axios";





export default function Payment() {
  return (

    <div style={styles.outerContainer}>
    <Header />
    <div style={styles.content}>
      <FormSection />
    </div>
    <Footer />
  </div>
  );

};

const Header = () => {


  return (
    <div className="header">

      <Link to="home" className='logo'>
        <img src={Logo} alt="Logo" />
      </Link>
      <a href="/Landing" className="buynow" >
        Home
      </a>
      <Link to="price" spy={true} smooth={true} duration={500} offset={-50} className="header-option">
        Price
      </Link>
      <Link to="function" spy={true} smooth={true} duration={500} offset={-50} className="header-option">
        Function
      </Link>
      <Link to="contact" spy={true} smooth={true} duration={500} offset={-50} className="header-option">
        Contact
      </Link>
      <Link to="aboutUs" spy={true} smooth={true} duration={500} offset={-50} className="header-option">
        About Us
      </Link>
      <a href="/payment" className="buynow" >
        Buy now
      </a>
      <a href="/register" className="login" >
                Register
            </a>
      <a href="/login" className="login" >
        Login
      </a>

    </div>
  );

}

const FormSection = () => {
  const [quantity, setQuantity] = useState('');
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [emailCheck, setEmailCheck] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [data, setData] = useState([]);
  const [nextNo, setnextNo] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch('https://641728d54f4c1cd3a328e3ad.mockapi.io/Classy');
      if (response.ok) {
        const data = await response.json();
        const maxNo = data.reduce((max, item) => Math.max(max, item.No), 0);
        const nextNo = maxNo + 1;
        setnextNo(maxNo + 1)

      } else {
        throw new Error(`Failed to fetch: ${response.statusText}`);
        
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    fetchData(); 
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Tính toán số tiền dựa trên số lượng lớp học
    let calculationResult;
    if (parseInt(quantity) < 10) {
      calculationResult = parseInt(quantity) * 10;
    } else {
      calculationResult = parseInt(quantity) * 7.99;
    }
    let resultLocal = calculationResult;
    
    // Cập nhật trạng thái để hiển thị thông tin
    setResult(calculationResult);
    setEmailCheck(email);
    
  
    // Chuẩn bị dữ liệu để gửi cho backend
    const paymentInfo = {
      result: parseInt(resultLocal), 
      email, 
      quantity: parseInt(quantity),
    };

    // Gửi thông tin tới backend để gửi email xác nhận
    try {
      const backendResponse = await axiosInstance.post('/user/request-payment/', paymentInfo);
      alert('We have emailed you, your order has been recorded successfully');
      setShowImage(true);

      // Chỉ gửi dữ liệu tới mockapi nếu gọi API trước đó thành công
      const paymentData = {
        No: nextNo,
        email: email,
        credit: calculationResult,
        status: "Pending",
        content: "",
        date: new Date().toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      };

      try {
        const response = await axios.post('https://641728d54f4c1cd3a328e3ad.mockapi.io/Classy', paymentData);
        setData([...data, response.data]);
      } catch (error) {
        console.error('Failed to record your order', error);
      }

    } catch (error) {
      console.error('Error processing payment information:', error.response);
      alert('This email has not registered an account on Classy')
    }
};

  



  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <form style={styles.form} onSubmit={handleSubmit}>
          <label style={styles.label}>Class amount:</label>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            style={styles.input}
          />

          <label style={styles.label}>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />

          <button type="submit" style={styles.button}>Place order</button>
        </form>

        {showImage && (
          <div style={styles.qrCode} >
            <img src={qr} alt="Example" style={styles.image} />
            {result !== null && (
              <p style={styles.resultText}>Total: ${result}</p>
            )}
            {emailCheck !== null && (
              <p style={styles.emailCheckText}>Content transfer: "{email}" or "{emailCheck}" </p>
             
            )}
            {emailCheck !== null && (
             
              <p style={styles.thankyou}>We have sent you an email, thank you for choosing Classy </p>
              
            )}
          </div>
        )}
      </div>
    </div>
   
  );
          }
const Footer = () => {
  return (
    <Element name="footer" id='footer' className="section">
      <div className='footer-container'>
        <div className='footer-left'>
          <p className='footer-title'>ABOUT CLASSY</p>
          <ul>
            <li><p>Introduce</p></li>
            <li><p>Product</p></li>
            <li><p>About Us</p></li>
            <li><p>News</p></li>
            <li><p>Contact</p></li>
          </ul>
        </div>

        <div className='footer-mid'>
          <p className='footer-title'> USER SUPPORT</p>
          <ul>
            <li><p>Support department</p></li>
            <li><p>Terms of use</p></li>
            <li><p>Privacy policy</p></li>
          </ul>
        </div>

        <div className='footer-right' >
          <p className='footer-title'>CONTACT US</p>
          <ul style={{ display: "flex", listStyleType: "none", fontSize: "40px" }}>
            <li style={{ marginRight: "10px", textDecoration: "none", color: "#5D9C59" }}>
              <a style={{ textDecoration: "none", color: "#5D9C59" }} href='https://www.instagram.com/classyvietnam/'>
                <InstagramFilled />
              </a>
            </li>
            <li>
              <a style={{ textDecoration: "none", color: "#5D9C59" }} href='www.facebook.com/classyedu'>
                <FacebookFilled />
              </a>
            </li>

          </ul>
        </div>
      </div>
    </Element>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    backgroundColor: '#f7f7f7',
  },
  image: {
    width: '200px', 
    height: '200px', 
   
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
  },
  label: {
    marginBottom: '5px',
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    height: '40px',
    padding: '10px',
    marginBottom: '15px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  button: {
    height: '45px',
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  qrCode: {
    marginLeft: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultText: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  emailCheckText: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  thankyou: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'green',
    
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flex: 1, 
  },
};
